<template>
  <div>
    <div class="title-conference">
      <h1>{{ conference_id[this.conference_id.length - 1].title }}</h1>
    </div>
    <v-card class="rounded-lg space-buttons" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          clearable
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Macrorregião..."
          single-line
          hide-details
          outlined
          dense
          @keyup.enter="searchRegister"
        ></v-text-field>
        <div class="space"></div>
        <v-select
          dense
          outlined
          clearable
          v-model="selectdStatus"
          :items="selects"
          item-text="name"
          item-value="value"
          label="Status"
        ></v-select>
        <div class="space"></div>

        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="searchRegister"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar </span>
        </v-btn>
        <div class="space"></div>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="#C62828"
          @click="clearSearch"
        >
          <span class="textBtn"> Limpar </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="regionalNucleusHealth"
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="totalregionalNucleusHealth"
        :sort-by.sync="sortBy"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud :resource="item" />
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div style="display: flex; justify-content: space-between">
            <span v-bind="attrs" v-on="on">
              {{
                Number(item.user[0].finished_release_conference)
                  ? "Concluído"
                  : "Aberto"
              }}
            </span>
            <span
              v-if="Number(item.user[0].finished_release_conference) == 1"
              class="dot-finished"
            ></span>
            <span
              v-if="Number(item.user[0].finished_release_conference) == 0"
              class="dot-not-finished"
            ></span>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./table-general-regional-nucleus-health";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      search1: "",
      conference_id: null,
      selectdStatus: null,
      macrorregiaoSelect: [],
      selectUF: [],
      selectedUF: null,
      macrorregiaoSelected: null,
      selects: [
        { name: "Concluído", value: true },
        { name: "Aberto", value: false },
      ],
      conference_idd: null,
      state_search: null,
      headers: [
        { text: "Ações", value: "actions", sortable: false, width: "20%" },
        {
          text: "MACRORREGIÃO",
          align: "start",
          value: "name",
          sortable: false,
        },

        {
          text: "ESTADO",
          align: "start",
          value: "state.name",
          sortable: false,
        },
        {
          text: "LANÇAMENTOS",
          align: "start",
          value: "description",
          sortable: false,
        },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],

      displayError: {
        display: false,
        message: [],
      },

      page: 1,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      searchMacro: "",
      sortBy: "name",
      redirect_edit: null,

      regionalNucleusHealth: [],
      totalregionalNucleusHealth: 0,

      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",

      name: "",
      State: "",
      saudeSelect: [],
      saudeSelected: null,
      StateList: [],
    };
  },

  components: {
    Crud,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    clearSearch() {
      this.search = "";
      this.state_search = "";
      this.searchMacro = "";
      this.selectdStatu = "";
      this.macrorregiaoSelected = "";
      this.saudeSelected = "";
      this.indoDataTable();
    },
    searchRegister() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      axios
        .get(
          `/regional-nucleus-health/users?page=${pageNumber}&per_page=${data.per_page}&name=${this.search}&state_id=${this.selectedUF}&finished_release_conference=${this.selectdStatus}`
        )
        .then((response) => {
          this.loading = false;
          this.regionalNucleusHealth = response.data.data;
          this.totalregionalNucleusHealth = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },
    switchStatus() {
      if (this.switchRegionalNucleus) return;
    },

    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      axios.get(`conference`).then((res) => {
        this.conference_idd = res.data;
        this.conference_id = [this.conference_idd.length - 1];
      });

      axios
        .get(
          `/regional-nucleus-health/users?page=${pageNumber}&per_page=${data.per_page}&name=${this.search}&state_id=${this.selectedUF}&finished_release_conference=${this.selectdStatus}`
        )
        .then((response) => {
          this.loading = false;
          this.regionalNucleusHealth = response.data.data;
          this.totalregionalNucleusHealth = response.data.total;
          this.numberOfPages = response.data.last_page;
        });

      axios.get("regional-nucleus-health").then((res) => {
        this.macrorregiaoSelect = res.data;
      });

      this.redirect_edit = Number(this.$store.state.profile_id);
      if (this.redirect_edit == 1 || this.redirect_edit == 2 || this.redirect_edit == 21 || this.redirect_edit == 41) {
        return;
      } else {
        this.$router.push({ path: "/dashboard" });
      }
    },
  },

  mounted() {
    this.indoDataTable();
    this.State = 5;
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space-buttons {
  margin: 0 20px;
}

.title-conference {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.space {
  width: 15px;
}

.dot-finished {
  height: 14px;
  width: 14px;
  background-color: rgb(198, 40, 40);
  border-radius: 50%;
  display: inline-block;
}

.dot-not-finished {
  height: 14px;
  width: 14px;
  background-color: #4caf50;
  border-radius: 50%;
  display: inline-block;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #27ae60, #1abc9c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
