<template>
  <div class="main-municipality">
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="#4c4c4c"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1"> FASE MUNICIPAL (Municípios) </v-tab>

        <v-tab href="#tab-2"> FASE MUNICIPAL (Instituições) </v-tab>

        <v-tab href="#tab-3"> FASE MACRORREGIONAL </v-tab>
        <v-tab href="#tab-4"> FASE ESTADUAL </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="tab-1">
          <tableMunicipality />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="2" value="tab-2">
          <tableInstitute />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="3" value="tab-3">
          <tableRegionalNucleusHealth />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="4" value="tab-4">
          <tableState />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import tableMunicipality from "../table-conference/table-municipality.vue";
import tableInstitute from "../table-conference/table-institute.vue";
import tableRegionalNucleusHealth from "../table-conference/table-regional-nucleus-health.vue";
import tableState from "../table-conference/table-state.vue";
import axios from "@/axios";

export default {
  data() {
    return {
      tab: null,
      user: [],
      switchStatus: null,
      switchRegister: null,
      switchColor: null,
    };
  },
  components: {
    tableMunicipality,
    tableInstitute,
    tableRegionalNucleusHealth,
    tableState
  },

  methods: {
    verifyStatusRegister() {
      if (this.resource.deleted_at == null) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },
    swithRegister() {
      if (!this.switchRegister) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },

    enableRegister() {
      axios
        .put(`/health-region/restore/${this.resource.id}`)
        .then((response) => {
          this.displaySuccess.message = response.data.message;
          this.displaySuccess.display = true;
        });
    },

    disableRegister() {
      axios.delete(`/health-region/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.responsibility {
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsibility span span {
  font-weight: 500;
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>
