<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            v-show="result"
            small
            class="mr-2 iconeEditar"
            @click="openDialogInstituteParticipant()"
          >
            mdi-account-multiple-plus
          </v-icon>
        </span>
      </template>
      <span>Quantidade de participantes</span>
    </v-tooltip>

    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            v-show="result"
            small
            class="mr-2 iconeEditar"
            @click="redirectEdit()"
          >
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="second">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            v-if="
              Number($store.state.profile_id) == 1 ||
              Number($store.state.profile_id) == 2 ||
              Number($store.state.profile_id) == 21 ||
              Number($store.state.profile_id) == 41
            "
            small
            class="mr-2 iconeEditar"
            @click="exportArchive()"
          >
            mdi-cloud-download
          </v-icon>
        </span>
      </template>
      <span>Exportar anexos da conferência</span>
    </v-tooltip>

    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon
            v-show="permissionLvl2"
            small
            class="mr-2 iconeEditar"
            @click="redirectEdit()"
          >
            mdi-eye-arrow-right
          </v-icon>
        </span>
      </template>
      <span>Visualizar</span>
    </v-tooltip>

    <div>
      <v-tooltip bottom :color="switchColor">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-switch
              v-model="switchRegionalNucleus"
              color="red"
              v-show="result"
              value
              @click="swithRegister()"
            ></v-switch>
          </span>
        </template>
        <span>{{ switchStatus }}</span>
      </v-tooltip>
    </div>

    <v-dialog v-model="dialogInstituteParticipants" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Quantidade de participantes</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-text-field
                v-mask="'######'"
                label="Quantidade de participantes"
                type="number"
                v-model="qty_participantes"
                clearable
              >
              </v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#C62828"
            style="color: white"
            @click="dialogInstituteParticipants = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="primary"  @click="saveInstituteParticipants()">
            {{ buttonDialogInstituteParticipants }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";

export default {
  data() {
    return {
      titleInstitute: "",
      qty_participantes: 0,
      dialogInstituteParticipants: false,
      buttonDialogInstituteParticipants: "",
      valid: true,
      nameRules: [(v) => !!v || "O campo é obrigatório"],
      resultButton: null,
      result: true,
      permissionLvl2: null,
      disabledd: false,
      authMe: null,
      State: "",
      redirect_edit: null,
      StateList: [],
      dialogEditar: false,
      dialogAPagar: false,
      switchRegionalNucleus: false,
      switchStatus: "",
      switchColor: "",

      displayError: {
        display: false,
        message: [],
      },

      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    AlertSuccess,
  },

  created() {
    this.verifyStatusRegister();
    this.verification();
  },

  methods: {
    saveInstituteParticipants() {
      let obj = new Object();
      obj.conference_id = this.$store.state.conference_id;
      obj.institute_id = this.resource.id;
      obj.qty_participantes = this.qty_participantes;
      axios.post("institute/institute-participants", obj).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
        this.dialogInstituteParticipants = false;
      });
    },
    openDialogInstituteParticipant() {
      axios
        .get("institute/institute-participants", {
          params: {
            conference_id: this.$store.state.conference_id,
            institute_id: this.resource.id,
          },
        })
        .then((response) => {
          if (response.data == "") {
            this.buttonDialogInstituteParticipants = "CADASTRAR";
            return;
          }
          this.buttonDialogInstituteParticipants = "ATUALIZAR";
          this.qty_participantes = response.data.qty_participantes;
        });
      this.dialogInstituteParticipants = true;
    },
    verifyStatusRegister() {
      if (Number(this.resource.user[0].restrict_release_conference)) {
        this.switchRegionalNucleus = true;
        this.switchStatus = "Desbloquear";
        this.switchColor = "red";
        return;
      }
      this.switchRegionalNucleus = false;
      this.switchStatus = "Bloquear";
      this.switchColor = "success";
    },

    exportArchive() {
      axios
        .post(
          `archives/export`,
          {
            conference_id: this.$store.state.conference_id,
            institute_id: this.resource.id,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/zip" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.resource.name + ".zip");
          document.body.appendChild(link);
          link.click();
        });
    },

    verification() {
      this.redirect_edit = Number(this.$store.state.profile_id);
      this.result = this.redirect_edit == 1 ? true : false;
      this.permissionLvl2 = this.redirect_edit == 2 ? true : false;
    },
    swithRegister() {
      if (!this.switchRegionalNucleus) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },
    enableRegister() {
      this.resultButton = {
        conference_id: this.$store.state.conference_id,
        institute_id: this.resource.id,
        restrict_release_conference: true,
      };
      axios
        .post(`conference/conference-restrict`, this.resultButton)
        .then((response) => {
          this.displaySuccess.message = response.data.message;
          this.displaySuccess.display = true;
        });
    },

    disableRegister() {
      this.resultButton = {
        institute_id: this.resource.id,
        restrict_release_conference: false,
      };
      axios
        .post(`conference/conference-restrict`, this.resultButton)
        .then((response) => {
          this.displaySuccess.message = response.data.message;
          this.displaySuccess.display = true;
        });
    },

    redirectEdit() {
      this.$router.push({
        path: "/dashboard/conference/institution",
        query: { id: this.$route.query.id, institute_id: this.resource.id },
      });
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
