import { render, staticRenderFns } from "./table-general-state.vue?vue&type=template&id=fa65c1ca&scoped=true&"
import script from "./table-general-state.vue?vue&type=script&lang=js&"
export * from "./table-general-state.vue?vue&type=script&lang=js&"
import style0 from "./table-general-state.vue?vue&type=style&index=0&id=fa65c1ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa65c1ca",
  null
  
)

export default component.exports